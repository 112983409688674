
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'sass:map';

// Plus imports for other components in your app.
@tailwind base;
@tailwind components;
@tailwind utilities;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import 'mat-palette-gen.scss';


$primary-color: #6738d6;
$accent-color: #ebb652;
$error-color: #d13212;
$confirmation-color: #128007;
$warning-color: #ec6800;
$accent2-color: #f24470;
$background-color: #f1f2f4;

$primary-palette: createPalette($primary-color);
$accent-palette: createPalette($accent-color);
$error-palette: createPalette($error-color);

$primary: mat.define-palette($primary-palette);
$accent: mat.define-palette($accent-palette);
$warn: mat.define-palette($error-palette);

$typography: mat.define-typography-config(
  $font-family: "Inter, sans-serif"
);

@include mat.typography-hierarchy($typography);


// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn
  ),
  typography: $typography,
  density: -2
));

$primary-contrast-color: map-get(map-get($primary-palette, 'contrast'), 500);
$accent-contrast-color: map-get(map-get($accent-palette, 'contrast'), 500);
$warn-contrast-color: map-get(map-get($error-palette, 'contrast'), 500);

:root {
  --primary: #{$primary-color};
  --accent: #{$accent-color};
  --error: #{$error-color};
  --success: #{$confirmation-color};
  --warning: #{$warning-color};
  --accent-2: #{$accent2-color};
  --background: #{$background-color};
  --primary-contrast: #{$primary-contrast-color};
  --accent-contrast: #{$accent-contrast-color};

  @include _output-palette-variables($primary-palette, 'primary');
  @include _output-palette-variables($accent-palette, 'accent');
  @include _output-palette-variables($error-palette, 'error');
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Inter, sans-serif;
}

.background {
  background-color: $background-color!important;
}

.mat-text-primary {
  color: $primary-color!important;
}

.mat-text-accent {
  color: $accent-color!important;
}

.mat-text-warn {
  color: $error-color!important;
}

.mat-text-success {
  color: $confirmation-color!important;
}

.mat-text-warning {
  color: $warning-color!important;
}

.mat-text-primary-contrast {
  color: $primary-contrast-color!important;
}

.mat-text-accent-contrast {
  color: $accent-contrast-color!important;
}

.mat-bg-primary {
  background-color: $primary-color!important;
}

.mat-bg-accent {
  background-color: $accent-color!important;
}

.mat-bg-warn {
  background-color: $error-color!important;
}

.mat-bg-warning {
  background-color: $warning-color!important;
}

.mat-bg-success {
  background-color: $confirmation-color!important;
}

.mat-full-primary {
  background-color: $primary-color!important;
  color: $primary-contrast-color!important;
}

.mat-full-accent {
  background-color: $accent-color!important;
  color: $accent-contrast-color!important;
}

.mat-full-warn {
  background-color: $error-color!important;
  color: $warn-contrast-color!important;
}

.confirmation-bg {
  background-color: $confirmation-color!important;
  color: white!important;
}

.mat-bg-card {
  $color-config: mat.get-color-config($theme);
  $background: map.get($color-config, background);
  background-color: mat.get-color-from-palette($background, 'card');
}

.mdc-notched-outline__notch { border-right: none; }

.mat-no-shadow {
  box-shadow: none!important;
}

.header-with-padding {
  .mat-sort-header-arrow {
    margin-right: 150px!important;
  }
}

.header-with-padding {
  .mat-sort-header-container:not(:has(.mat-sort-header-arrow)) .mat-sort-header-content {
    margin-right: 150px!important;
  }
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}

.code-box {
  overflow-wrap: anywhere;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
  padding: 0.5rem;
}

.blockbuilder-branding {
  display: none!important;
}

.status-badge {
  background-color: #999;
  color: white;
  font-weight: bold;
  padding: 0.25rem 1rem;
  border-radius: 5px;
}

.flat-card {
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
}


.no-dimension {
  width: 0!important;
  height: 0!important;
}

.mdc-list-item__primary-text {
  width: 100%;
}

.menu-item .mat-expansion-panel-body {
  padding: 0;
}

.container-height {
  height: calc(100% - var(--mat-toolbar-standard-height));
  max-height: calc(100% - var(--mat-toolbar-standard-height));
  @media (max-width: 599px) {
    height: calc(100% - var(--mat-toolbar-mobile-height));
    max-height: calc(100% - var(--mat-toolbar-mobile-height));
  }
}

.color-bg {
  width: 100%;
  height: 100%;
  background-color:hsla(232,0%,100%,1);
  background-image:
    radial-gradient(at 62% 97%, hsla(257,65%,52%,0.5) 0px, transparent 50%),
    radial-gradient(at 14% 52%, hsla(39,71%,59%,0.5) 0px, transparent 50%),
    radial-gradient(at 82% 35%, hsla(332,85%,53%,0.5) 0px, transparent 50%),
    radial-gradient(at 21% 87%, hsla(224,100%,50%,0.5) 0px, transparent 50%);
}
